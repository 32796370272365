import React from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import TextContainer from 'components/common/text-container'
import Navigation from 'components/navigation'
import Layout from 'components/layout'
import { Heading, SubHeading } from 'components/common/typography'

const StyledImg = styled.img`
  width: 100%;
`

const ContentfulHeading = (_, children) => <SubHeading>{children}</SubHeading>
const ContentfulAsset = (node, _) => (
  <StyledImg src={`${node.data.target.fields.file['en-US'].url}`} />
)

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: ContentfulHeading,
    [BLOCKS.HEADING_2]: ContentfulHeading,
    [BLOCKS.HEADING_3]: ContentfulHeading,
    [BLOCKS.EMBEDDED_ASSET]: ContentfulAsset,
  },
}

export default class PortfolioCategoryTemplate extends React.Component {
  render() {
    const {
      data: { blog },
    } = this.props

    return (
      <Layout>
        <Helmet
          title={`${blog.title} | Pinya Letters`}
          meta={[
            {
              name: 'description',
              content: blog.description,
            }, {
              name: 'og:image',
              content: blog.previewImage.fixed.src,
            },
          ]}
        ></Helmet>
        <Navigation solidNav />
        <TextContainer>
          <br />
          <br />
          <Heading>{blog.title}</Heading>

          {documentToReactComponents(blog.content.json, options)}
        </TextContainer>

        <TextContainer>
          <SubHeading as='h3'>See my other blog posts</SubHeading>
          Take a look at the <Link to='/blog'>rest of my blog posts</Link>!
        </TextContainer>
      </Layout>
    )
  }
}

export const query = graphql`
  query BlogTemplateQuery($slug: String!) {
    blog: contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      description

      previewImage {
        id

        fixed(width: 500, height: 500, quality: 100) {
          ...GatsbyContentfulFixed
        }
      }

      content {
        json
      }
    }
  }
`
